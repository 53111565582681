/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Actions from 'actions';
import Selectors from 'selectors';
import { useSelector, useDispatch } from 'react-redux';
import { initSendBird } from 'utils/sendbird';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from 'utils/routes';
import isEmpty from 'lodash/isEmpty';
import PublicLayout from 'containers/LandingPage';
import MemberLayout from 'containers/MemberPage';
import { AuthContext } from 'utils/context';
import CONFIG, { isProduction } from 'utils/config';
import SendBird from 'sendbird';
import ReactPixel from 'react-facebook-pixel';
import { useCookies } from 'react-cookie';
import { NewsletterModal } from 'components/UI';
import LinkedInTag from 'react-linkedin-insight';
import { ALL_ACCESS, PREMIUM } from 'utils/constants';

import Dev from 'containers/Dev';
import Chat from 'containers/Dev/Chat';
import Stripe from 'containers/Stripe';
import { storeUTMParameters } from '../utils/analytics';

const PrivateRoute = (props) => {
  const { pathname } = useLocation();
  const { authenticated, isSubscribed, hasAccess } = useContext(AuthContext);

  if (!authenticated)
    return (
      <Navigate
        to={pathname ? `login?redirectPath=${pathname}` : '/'}
        replace
      />
    );

  if (!hasAccess) {
    const route = PRIVATE_ROUTES.find(({ path }) => pathname.startsWith(path));
    if (route && !route.availableInUnpaid)
      return <Navigate to="/subscription" replace />;
  }

  if (!isSubscribed) return <Navigate to="/activate" replace />;
  return <Route {...props} />;
};

const DevRoute = (props) => {
  if (isProduction) return <Navigate to="/" replace />;
  return <Route {...props} />;
};

const routeHandler = (route) => {
  if (!isEmpty(route.children)) {
    return (
      <Route path={route.path} key={route.path}>
        {route.children.map((child) => (
          <Route path={child.path} element={child.component} key={child.path} />
        ))}
      </Route>
    );
  }
  return <Route path={route.path} element={route.component} key={route.path} />;
};

const App = () => {
  const [cookies, setCookie] = useCookies(['firstVisit']);
  const [showNewsletterModal, setNewsletterModal] = useState(false);
  const { pathname } = useLocation();
  const user = useSelector(Selectors.getUser) || {};
  const {
    status,
    member_tier: tier,
    sendbird_id: sendbirdId,
    active_at: activeAt,
    all_access_event_eligible: allAccessEventEligible,
  } = user;
  const isSbConnected = useSelector(Selectors.isSbConnected);
  const dispatch = useDispatch();
  const authenticated = !isEmpty(user);
  const hasAccess = status === 'paid' || status === 'pending_payment';
  const isPro = tier?.name === ALL_ACCESS;
  const isFreeTier = tier?.is_free_tier;
  const isLite = tier?.name === PREMIUM;
  const linkedInTag = LinkedInTag;
  const isSubscribed =
    ['paid', 'unpaid', 'cancelled'].includes(status) ||
    (status === 'pending_payment' && !!activeAt);

  useEffect(() => {
    if (isProduction) {
      ReactPixel.init(CONFIG.FB_PIXEL, null, {
        autoConfig: true,
        debug: false,
      });
      LinkedInTag.init(CONFIG.LINKEDIN_TAG_ID, 'dc', false);
    }
    storeUTMParameters();
  }, []);

  useEffect(() => {
    if (isProduction) {
      ReactPixel.pageView();
    }
  }, [pathname]);

  useEffect(() => {
    if (authenticated) {
      initSendBird();
    }
  }, [authenticated]);

  useEffect(() => {
    if (hasAccess) {
      dispatch(Actions.connectSendbird());
    }
  }, [dispatch, sendbirdId, hasAccess]);

  useEffect(() => {
    // NOTES: handle sendbird instance's events here
    const sb = isSbConnected && SendBird.getInstance();

    if (sb) {
      const ChannelHandler = new sb.ChannelHandler();
      ChannelHandler.onChannelChanged = () => {
        dispatch(Actions.listChannels());
      };
      ChannelHandler.onMessageReceived = (channel) => {
        dispatch(Actions.listChannels());
        dispatch(Actions.listMessages(channel));
      };
      sb.addChannelHandler('ROOM_MESSAGE_HOOK', ChannelHandler);
    }
    return () => {
      if (sb) {
        sb.removeChannelHandler('ROOM_MESSAGE_HOOK');
      }
    };
  }, [dispatch, isSbConnected]);

  useEffect(() => {
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    if (
      !cookies.lastModalShown ||
      new Date().getTime() - parseInt(cookies.lastModalShown, 10) >
        oneDayInMilliseconds
    ) {
      if (!authenticated) {
        setTimeout(() => {
          setNewsletterModal(true);
        }, 5000);
      }

      setCookie('lastModalShown', new Date().getTime(), {
        path: '/',
        expires: new Date(2100, 1),
      });
    }
  }, [cookies, setCookie, authenticated]);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        hasAccess,
        isPro,
        isSubscribed,
        isFreeTier,
        isLite,
        linkedInTag,
        tier,
        allAccessEventEligible,
      }}>
      <Routes>
        <Route path="/" element={<PublicLayout />}>
          {PUBLIC_ROUTES.map(routeHandler)}
        </Route>
        <PrivateRoute path="/" element={<MemberLayout />}>
          {PRIVATE_ROUTES.map(routeHandler)}
          <DevRoute path="/dev-chat" element={<Chat />} />
          <DevRoute path="/dev-auth" element={<Dev />} />
        </PrivateRoute>
        <DevRoute path="/dev" element={<Dev />} />
        <DevRoute path="/stripe" element={<Stripe />} />
      </Routes>
      <NewsletterModal isOpen={showNewsletterModal} />
    </AuthContext.Provider>
  );
};

export default App;
