import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Actions from 'actions';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CONFIG from 'utils/config';
import notify from 'utils/notification';
import isEmpty from 'lodash/isEmpty';
import ReactHtmlParser from 'react-html-parser';
import {
  iconClock,
  iconDate,
  iconLocation,
  iconAddEvent,
  iconAddedEvent,
  reportEventIcon,
  iconVenue,
} from 'assets';
import {
  Badge,
  Button,
  Card,
  Picture,
  ImageFilter,
  PageMeta,
  Modal,
  SocialMediaShare,
  Options,
  FlagForm,
} from 'components/UI';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import {
  displayTimeFormat,
  displayDateFormat,
  displayDayDateFormat,
} from 'utils/datetime';
import { AuthContext } from 'utils/context';
import { urlHandler } from 'utils/helper';
import qs from 'query-string';
import {
  ALL_ACCESS,
  ALL_MEMBERS,
  ALL_ACCESS_ONLY,
  PREMIUM_ALL_ACCESS,
  PREMIUM,
  FREE,
} from 'utils/constants';
import { BackgroundSection, labelBg } from '../LandingPage/common';
import EventModal from './EventModal';
import RsvpList from './RsvpList';
import GuestInfoForm from './GuestInfoForm';
import { liteDialog } from '../Subscription/dialogs';
import MemberAccessBadge, { memberAccessMap } from './MemberAccessBadge';

const OptionFlagEvent = () => (
  <span>
    <Picture src={reportEventIcon} className="inline-block w-4 mr-2" />
    Flag Event
  </span>
);

const EventViewCard = ({ eventInfo, isLoading, isUserProEligible }) => {
  const {
    id,
    city,
    name,
    image_url: imageUrl,
    start_at: startAt,
    end_at: endAt,
    description,
    link,
    attendances_count: attendancesCount,
    max_rsvp_limit: maxRSVPLimit,
    attendees_preview: attendeesPreview,
    current_user_rsvped: attending,
    type,
    event_category: eventCategory,
    time_zone: timeZone,
    webinar_link: webinarLink,
    current_user_saved: eventAdded,
    published,
    member_access: memberAccess,
    slots_by_tier: slotsByTier,
    guest_info: guestInfo,
    member_access_quotas: memberAccessQuotas,
    ticket_price: ticketPrice,
    venue,
  } = eventInfo;

  const [isEventAdded, setIsEventAdded] = useState(eventAdded);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    authenticated,
    isPro,
    hasAccess,
    isFreeTier,
    isLite,
    allAccessEventEligible,
  } = useContext(AuthContext);

  const allAccessSlots = slotsByTier?.filter(
    ({ member_tier: memberTier }) => memberTier === ALL_ACCESS
  );
  const filteredSlots =
    memberAccess === PREMIUM_ALL_ACCESS
      ? slotsByTier?.filter(
          ({ member_tier: memberTier }) => memberTier !== FREE
        )
      : allAccessSlots;
  const slots = memberAccess === ALL_MEMBERS ? slotsByTier : filteredSlots;
  const noQuotaSlots = slots?.[0].slots;
  const remainingSlots = slots?.reduce((sum, slot) => sum + slot.slots, 0) || 0;
  const remainingSlotsInfo =
    remainingSlots > 0 ? 'Remaining slots' : 'Fully booked';
  const showGuestForm = isPro && attending;
  const showAllAccessPaywall =
    !allAccessEventEligible &&
    memberAccess === ALL_ACCESS_ONLY &&
    ticketPrice > 0;

  const eventType = eventCategory?.name || 'Festival';
  const eventTypeUrl = `/events?${qs.stringify({ category: eventType })}`;
  const shareLinkUrl = `${CONFIG.WEB_URL}/events/${id}`;
  const userEvent = type === 'Event::User';

  const flagEvent = () =>
    dispatch(
      Actions.showDialog({
        title: 'Flag Event',
        content: <FlagForm eventId={id} />,
        hideButtons: true,
      })
    );

  const optionList = [
    {
      label: <OptionFlagEvent />,
      key: 'click-flag',
      onClick: flagEvent,
    },
  ];

  const time = () => {
    const start = displayTimeFormat(startAt);
    return endAt ? `${start} - ${displayTimeFormat(endAt)}` : start;
  };
  const eventDate = () => {
    const startDate = displayDayDateFormat(startAt, timeZone);
    if (endAt) {
      const endDate = displayDayDateFormat(endAt, timeZone);
      if (startDate !== endDate) return `${startDate} - ${endDate}`;
    }
    return startDate;
  };

  const redirectLink = () => {
    // eslint-disable-next-line no-control-regex
    window.open(link.replace(/[^\x00-\x7F]/g, ''), '_blank');
    return null;
  };

  const ExternalBtn = () => {
    if (isEmpty(link)) return null;
    return (
      <div className="flex flex-col p-5 border-gray-400 border rounded items-center mb-10">
        <Button
          label="Get Ticket"
          className="w-64 h-12"
          onClick={redirectLink}
        />
      </div>
    );
  };

  const InternalBtn = () => {
    const BookingBtn = () =>
      link ? (
        <Button
          label="BOOK A SLOT"
          className="w-64 h-12"
          onClick={redirectLink}
        />
      ) : (
        <>
          <EventModal
            eventInfo={eventInfo}
            attending={attending}
            isLoading={isLoading}
          />
          <div className="border rounded-lg p-2 mt-5 flex flex-col items-center w-full">
            {showAllAccessPaywall && (
              <>
                <p className="text-easter text-lg text-center mt-5">
                  USD {ticketPrice}/slot
                </p>
                <div className="w-full border-gray-200 border-t my-3" />
              </>
            )}
            <p className="text-dusty text-center">
              {!maxRSVPLimit ? 'RSVPs opening soon' : remainingSlotsInfo}
            </p>
            <div className="flex items-center mt-3 w-full justify-around">
              {maxRSVPLimit &&
                (memberAccessQuotas.length ? (
                  slots.map((slot) => (
                    <div
                      key={slot.member_tier}
                      className={classnames(
                        'flex flex-col items-center w-1/3 sm:border-r',
                        {
                          'sm:border-r': slots?.length === 3,
                          'sm:border-none':
                            slot.member_tier === 'Free' || slots?.length !== 3,
                        }
                      )}>
                      <p className="font-semibold text-2lg">{slot.slots}</p>
                      <p
                        className={classnames('text-xs md:text-sm', {
                          'text-dusty': slot.member_tier === 'Free',
                          'text-easter': slot.member_tier === PREMIUM,
                          'text-violet': slot.member_tier === ALL_ACCESS,
                        })}>
                        {slot.member_tier}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="font-semibold text-2lg text-dusty">
                    {noQuotaSlots}
                  </p>
                ))}
            </div>
          </div>
        </>
      );

    const Content = () => {
      const upgradeToPro = () =>
        dispatch(
          Actions.showDialog({
            ...liteDialog(),
            subContent: `Let's upgrade to ${ALL_ACCESS} membership to be eligible to join this event.`,
            onAccept: () => {
              navigate('/subscription/update', {
                state: { tierToUpdate: ALL_ACCESS },
              });
              dispatch(Actions.hideDialog());
            },
            className: 'bg-black-cover z-50',
          })
        );

      if (authenticated) {
        if (
          isPro ||
          memberAccess === ALL_MEMBERS ||
          (isLite && memberAccess === PREMIUM_ALL_ACCESS)
        )
          return <BookingBtn />;

        return (
          <>
            <p className="mb-3 text-dusty">{`${memberAccessMap[memberAccess]} members only`}</p>
            {memberAccess === ALL_ACCESS_ONLY && !isUserProEligible ? (
              <Button label="Unavailable" className="w-64 h-12" disabled />
            ) : (
              <Button
                label="UPGRADE NOW TO JOIN"
                className="px-5 w-64 h-12"
                onClick={
                  isFreeTier ? () => navigate('/subscription') : upgradeToPro
                }
                isPill
              />
            )}
          </>
        );
      }

      return (
        <>
          <p className="mb-3 text-dusty">
            {memberAccess === ALL_MEMBERS
              ? 'Members Only'
              : `${memberAccessMap[memberAccess]} members only`}
          </p>
          <Button
            label="Apply To Be a Member"
            className="w-64 h-12"
            onClick={() => navigate('/signup')}
            isPill
          />
        </>
      );
    };

    return (
      <div className="flex flex-col p-5 border-gray-400 border rounded items-center mb-10">
        <Content />
      </div>
    );
  };

  const notifyFail = () =>
    notify('Something went wrong, please try again later', null, 'danger');

  const addEvent = () => {
    dispatch(
      Actions.addEvent(id, {
        success: () => {
          notify('Event added to your events');
          setIsEventAdded(true);
        },
        fail: notifyFail,
      })
    );
  };

  const removeEvent = () => {
    dispatch(
      Actions.removeEvent(id, {
        success: () => setIsEventAdded(false),
        fail: notifyFail,
      })
    );
  };

  return (
    <>
      <PageMeta title={name} ogImg={imageUrl} />
      <Card key={id} className="h-full relative mt-8">
        <BackgroundSection
          backgroundImage={`url(${urlHandler(imageUrl)})`}
          className="relative bg-no-repeat bg-cover bg-center h-64 md:h-140 rounded-lg">
          <ImageFilter className="rounded-t-lg" />
          <div className="flex flex-col text-white justify-end h-full relative">
            {attending && (
              <div className="flex items-center justify-center ml-auto mb-auto mt-5 mr-5 bg-brilliantGreen h-8 w-20 rounded-full">
                Booked
              </div>
            )}
            <div className="flex flex-col mb-10 ml-5 md:m-10">
              <div className="mb-2 flex flex-col items-start gap-4 sm:flex-row sm:items-center sm:gap-10">
                <Link to={eventTypeUrl}>
                  <Badge bg={labelBg(eventType)}>{eventType}</Badge>
                </Link>
                {!!memberAccess && (
                  <MemberAccessBadge memberAccess={memberAccess} />
                )}
              </div>
              <div className="lg:text-5xl text-lg md:text-xl font-chap">
                {name}
              </div>
              <div className="font-thin font-lynstone text-md md:text-lg">
                <span className="uppercase mr-1">{city?.name || 'Global'}</span>
                •
                <span className="ml-1">
                  {displayDateFormat(startAt, timeZone)}
                </span>
              </div>
            </div>
          </div>
          {hasAccess && published && (
            <>
              <div className="flex items-center absolute bottom-0 right-0">
                {userEvent && <Options list={optionList} />}
                <div
                  className="flex items-center justify-center cursor-pointer bg-gray-500 bg-opacity-50 h-10 sm:h-12 rounded-tl-lg px-4"
                  onClick={isEventAdded ? removeEvent : addEvent}
                  role="presentation">
                  <Picture
                    src={isEventAdded ? iconAddedEvent : iconAddEvent}
                    className="w-5 h-5 sm:w-6 sm:h-6"
                  />
                  <p
                    className={classnames(
                      'uppercase text-gray-400 ml-3 text-xs sm:text-base',
                      { 'text-easter': isEventAdded }
                    )}>
                    {`${isEventAdded ? 'added' : 'add'} to my events`}
                  </p>
                </div>
              </div>
            </>
          )}
        </BackgroundSection>
        <div className="pt-10 sm:px-8 px-4 flex flex-wrap lg:flex-no-wrap">
          <div className="flex flex-col lg:w-2/3 w-full">
            <div className="uppercase mt-1 font-lynstone text-lg font-semibold">
              About this event
            </div>
            <div className="my-5">{ReactHtmlParser(description)}</div>
          </div>
          <div className="lg:w-1/2 xl:w-1/3 w-full sm:pl-5 mb-30">
            {type === 'Event::Internal' ? <InternalBtn /> : <ExternalBtn />}
            {type === 'Event::Internal' && showGuestForm && (
              <GuestInfoForm
                guestInfo={guestInfo}
                id={id}
                allAccessSlots={allAccessSlots}
              />
            )}
            <div className="mt-2 pl-5 flex flex-col max-w-xs">
              <div className="uppercase text-gray-500 text-sm">
                Date &amp; Time
              </div>
              <div className="flex mt-4 items-center">
                <Picture src={iconDate} className="w-5 mr-4" />
                <div>{eventDate()}</div>
              </div>
              <div className="flex flex-col mt-4">
                <div className="flex items-center">
                  <Picture src={iconClock} className="w-5 mr-4" />
                  <div>{time()}</div>
                </div>
                <p className="text-gray-500 ml-8">Your local timezone</p>
              </div>
            </div>
            <div className="mt-12 pl-5 flex flex-col max-w-xs">
              <div className="uppercase text-gray-500 text-sm">Location</div>
              <div className="flex mt-4 items-center">
                <Picture src={iconLocation} className="w-4 mr-4" />
                <div className="uppercase">{city?.name || 'Global'}</div>
              </div>
              {venue && (
                <>
                  <p className=" mt-12 uppercase text-gray-500 text-sm">
                    Venue
                  </p>
                  <div className="flex mt-4 items-center">
                    <Picture src={iconVenue} className="w-5 mr-5" />
                    <p className="uppercase">{venue}</p>
                  </div>
                </>
              )}
              {attending && webinarLink && (
                <div className="mt-4 flex flex-col">
                  <p className="text-gray-600">Webinar Link</p>
                  <a
                    href={webinarLink}
                    rel="noreferrer"
                    target="_blank"
                    className="text-easter break-all">
                    {webinarLink}
                  </a>
                </div>
              )}
            </div>
            {hasAccess && !link && attendancesCount > 0 && (
              <div className="mt-12 pl-5 flex flex-col max-w-xs">
                <div className="flex justify-between items-center mb-4">
                  <div className="uppercase text-gray-500 text-sm">
                    Who&apos;s Joining
                  </div>
                  <Modal content={<RsvpList eventId={id} />}>
                    <div className="flex items-center cursor-pointer text-sigmaBlue-dark sm:ml-6 text-base">
                      <div>View All</div>
                      <DoubleArrowIcon className="sm:mx-2" fontSize="inherit" />
                    </div>
                  </Modal>
                </div>
                <div className="flex flex-wrap mt-auto">
                  {attendeesPreview?.map(({ id, image_url: img }) => (
                    <Link
                      key={id}
                      className="rounded-full h-10 w-10 mx-1 shadow-lg overflow-hidden"
                      to={`/network/${id}`}
                      role="presentation">
                      <Picture
                        src={img}
                        className="object-cover w-full h-full"
                      />
                    </Link>
                  ))}
                  {attendancesCount > attendeesPreview?.length && (
                    <div className="w-12 h-10 px-1">
                      <div className="flex shadow rounded-full object-cover max-w-full h-full align-middle border-none bg-sigmaBlue-dark">
                        <div className="m-auto text-white text-sm">
                          {attendancesCount > 999
                            ? '>999'
                            : `+${attendancesCount - attendeesPreview?.length}`}
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="mt-12 max-w-xs pl-5">
              <div className="uppercase text-gray-500 text-sm hidden sm:block">
                Share this event
              </div>
              <SocialMediaShare
                title={name}
                url={shareLinkUrl}
                summary={description}
                className="hidden sm:block"
                horizontal
                linkName="Event"
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

EventViewCard.propTypes = {
  eventInfo: PropTypes.object.isRequired,
  isUserProEligible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

EventViewCard.defaultProps = {
  isLoading: true,
};

export default EventViewCard;
